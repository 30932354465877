import React from 'react';
import classnames from 'classnames';

import LanguageSelector from 'components/Globals/LanguageSelector';
import ProfileDropdown, { useHandleProfileCardClick } from 'components/Globals/ProfileDropdown';
import LinkButton, { PrimaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useAppContext from 'utils/hooks/useAppContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useProfileAccesses, useUserData } from 'utils/hooks/useAuthenticatedUser';
import { getMediaSource } from 'utils/media';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './HeaderRightBlock.module.scss';

const LoggedInView = ({ trackingData }) => {
  const { isDesktop } = useDeviceTypeLayouts();
  const userData = useUserData();
  const { data: profileAccesses } = useProfileAccesses();
  const { handleProfileCardClick } = useHandleProfileCardClick(userData, trackingData);

  if (!isDesktop) {
    return <ProfileDropdown />;
  }

  return (
    <nav>
      <ul className={classes.rightBlockLoggedIn}>
        {(profileAccesses?.data || [])?.slice(0, 2)?.map(entity => {
          const determineTargetEntity = profile => {
            if (profile?.organization && Object.keys(profile.organization).length > 0) {
              return { ...profile.organization, entityType: ENTITY_TYPES.ORGANIZATION };
            }
            if (profile?.agency && Object.keys(profile.agency).length > 0) {
              return { ...profile.agency, entityType: ENTITY_TYPES.AGENCY };
            }
            if (profile?.profile || profile) {
              const targetProfile = profile?.profile || profile;
              return { ...targetProfile, entityType: ENTITY_TYPES.PROFILE };
            }
            return null;
          };
          const profile = determineTargetEntity(entity);
          const src = getMediaSource({
            id: profile?.id,
            file: { urls: profile?.image || profile?.logo },
          });

          return (
            <li key={profile.id} className={classes.profileItem}>
              <LinkButton
                variant="text"
                title={profile.name}
                styles={{ root: classes.profileLink }}
                disableUnderline
                shape="rect"
                onClick={e => handleProfileCardClick(profile, e, profile?.isPending)}
              >
                {profile?.image ? (
                  <Image
                    src={src}
                    alt={profile.name}
                    width={26}
                    height={32}
                    disableNextImage
                    transformations={
                      profile?.entityType === ENTITY_TYPES.ORGANIZATION
                        ? TRANSFORMATIONS.AOS_LOGO
                        : TRANSFORMATIONS.PROFILE_THUMBNAIL
                    }
                  />
                ) : (
                  <ImageAvatar name={profile?.name} width={26} height={32} fontSize={14} shape="rect" type="V4" />
                )}
              </LinkButton>
            </li>
          );
        })}
        <li
          className={classnames(classes.profileDropdown, {
            [classes.profileDropdown__withoutBorder]: !profileAccesses?.data?.length,
          })}
        >
          <ProfileDropdown />
        </li>
      </ul>
    </nav>
  );
};
const NonLoggedInView = ({ trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { setIsLoginDialog } = useDialogs();
  const { isDesktop } = useDeviceTypeLayouts();

  if (!isDesktop) {
    return (
      <PrimaryButton
        onClick={() => setIsLoginDialog({ isOpen: true })}
        shape="rectangle"
        size="medium"
        trackingData={{
          ...trackingData,
          subComponent: SUB_COMPONENTS.LOGIN_CTA,
        }}
      >
        {t(`${TP}.m_LOGIN`)}
      </PrimaryButton>
    );
  }

  return (
    <ul className={classes.rightBlock}>
      <li className={classes.navItem}>
        <LanguageSelector type="header" />
      </li>
      <li>
        <PrimaryButton
          onClick={() => setIsLoginDialog({ isOpen: true })}
          shape="rectangle"
          leftIcon={<SpriteIcon icon="account_circle" size={14} />}
          styles={{ icon: classes.accountIcon }}
          size="medium"
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.LOGIN_CTA,
          }}
        >
          {t(`${TP}.m_LOGIN`)}
        </PrimaryButton>
      </li>
    </ul>
  );
};

const HeaderRightBlock = ({ trackingData }) => {
  const { isLoggedIn } = useAppContext();

  return isLoggedIn ? <LoggedInView trackingData={trackingData} /> : <NonLoggedInView trackingData={trackingData} />;
};

export default HeaderRightBlock;
