import routesWithTitle from 'constants/routesWithTitle';
import { FILTER_TYPES, ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import { TP, ORGANIZATION_TYPE_SLUGS, ORGANIZATION_TYPE_IDS, ENTITY_MAIN_TABS, MEDIA_TYPES_IDS } from 'constants/index';

export const NAVIGATION_KEYS = {
  PERFORMANCES: 'performances',
  ARTISTS: 'artists',
  ORGANIZATIONS: 'organizations',
  MANAGERS: 'managers',
  VENUES: 'venues',
  SEASONS: 'seasons',
  COMPETITIONS: 'competitions',
  CREATORS: 'creators',
  WORKS: 'works',
  WATCH_ONLINE: 'online',
  GENRE: 'genre',
};

export const NAV_LINKS = {
  [NAVIGATION_KEYS.PERFORMANCES]: {
    ...routesWithTitle.PRODUCTIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.COUNTRY,
      entity: option,
    }),
  },
  [NAVIGATION_KEYS.ARTISTS]: {
    ...routesWithTitle.ARTISTS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.PROFILE,
      filters: {
        [FILTER_TYPES.PROFESSION]: [
          {
            entityType: ENTITY_TYPES.PROFESSION,
            entity: option,
          },
        ],
      },
    }),
  },
  [NAVIGATION_KEYS.ORGANIZATIONS]: {
    ...routesWithTitle.ORGANIZATIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.ORGANIZATION,
      filters: {
        [FILTER_TYPES.ORGANIZATION_TYPE]: [
          {
            entityType: ENTITY_TYPES.ORGANIZATION_TYPE,
            entity: option,
          },
        ],
      },
    }),
  },
  [NAVIGATION_KEYS.MANAGERS]: {
    ...routesWithTitle.MANAGERS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.AGENCY,
      filters: {
        [FILTER_TYPES.COUNTRY]: [
          {
            entityType: ENTITY_TYPES.COUNTRY,
            entity: option,
          },
        ],
      },
    }),
  },
  [NAVIGATION_KEYS.VENUES]: {
    ...routesWithTitle.VENUES,
    linkProps: option => ({
      entityType: ENTITY_TYPES.ORGANIZATION,
      filters: {
        [FILTER_TYPES.ORGANIZATION_TYPE]: [
          {
            entityType: ENTITY_TYPES.ORGANIZATION_TYPE,
            entity: { id: ORGANIZATION_TYPE_IDS.VENUE, slug: ORGANIZATION_TYPE_SLUGS[ORGANIZATION_TYPE_IDS.VENUE] },
          },
        ],
        [FILTER_TYPES.COUNTRY]: [
          {
            entityType: ENTITY_TYPES.COUNTRY,
            entity: option,
          },
        ],
      },
    }),
  },
  [NAVIGATION_KEYS.SEASONS]: {
    ...routesWithTitle.SEASONS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.ORGANIZATION,
      entity: option,
      path: ENTITY_MAIN_TABS.PERFORMANCES,
    }),
  },
  [NAVIGATION_KEYS.COMPETITIONS]: {
    ...routesWithTitle.COMPETITIONS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.ORGANIZATION,
      filters: {
        [FILTER_TYPES.ORGANIZATION_TYPE]: [
          {
            entityType: ENTITY_TYPES.ORGANIZATION_TYPE,
            entity: {
              id: ORGANIZATION_TYPE_IDS.COMPETITION,
              slug: ORGANIZATION_TYPE_SLUGS[ORGANIZATION_TYPE_IDS.COMPETITION],
            },
          },
        ],
        [FILTER_TYPES.COUNTRY]: [
          {
            entityType: ENTITY_TYPES.COUNTRY,
            entity: option,
          },
        ],
      },
    }),
  },
  [NAVIGATION_KEYS.CREATORS]: {
    ...routesWithTitle.COMPOSERS,
    title: `${TP}.CREATORS`,
    linkProps: option => ({
      entityType: ENTITY_TYPES.PROFILE,
      entity: option,
    }),
  },
  [NAVIGATION_KEYS.WORKS]: {
    ...routesWithTitle.WORKS,
    linkProps: option => ({
      entityType: ENTITY_TYPES.WORK,
      entity: option,
    }),
  },
  [NAVIGATION_KEYS.WATCH_ONLINE]: {
    ...routesWithTitle.VIDEOS,
    title: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
    linkProps: option => ({
      baseRoute: `${ROUTE_RESERVED_KEYWORDS.videos}/${ROUTE_RESERVED_KEYWORDS.search}`,
      queryParams: {
        has_posters: true,
        is_valid_ls_vod: true,
        is_production_published: true,
        media_type_id: MEDIA_TYPES_IDS.LS,
        ...(option?.organizationType?.id ? { organization_id: option?.id } : { individual_id: option?.id }),
      },
    }),
  },
};
