import React from 'react';

import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import NavLink from '../NavLink';
import History from '../History';

import classes from './HeaderBottomBlock.module.scss';

const HeaderBottomBlock = ({ links = [], trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { setIsMenuOpen } = useDialogs();
  if (!isDesktop) {
    return (
      <HorizontalScroller hideArrow>
        <nav>
          <ul className={classes.mobileBottomBlock}>
            {links.map(link => (
              <li className={classes.navItem}>
                <NavLink
                  key={link.title}
                  trackingData={{
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.HEADER_LINKS,
                  }}
                  typographyProps={{ title: { size: 12 } }}
                  {...link}
                />
              </li>
            ))}
          </ul>
        </nav>
      </HorizontalScroller>
    );
  }

  return (
    <HorizontalScroller hideArrow>
      <div className={classes.bottomBlock}>
        <div className={classes.menuSection}>
          <NavLink
            title={t(`${TP}.MENU`)}
            styles={{ icon: classes.icon, root: classes.menu }}
            onClick={() => setIsMenuOpen(true)}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.MENU,
            }}
            rightIcon={<SpriteIcon icon="menu" size="14" className={classes.menuIcon} />}
          />
          <div className={classes.divider} />
        </div>
        <History trackingData={trackingData} />
        <nav>
          <ul className={classes.links}>
            {links.map((link, index) => (
              <li className={classes.navItem}>
                <NavLink
                  key={`${link.title}-${index}`}
                  trackingData={{
                    ...trackingData,
                    subComponent: SUB_COMPONENTS.HEADER_LINKS,
                  }}
                  {...link}
                  dropdown
                />
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </HorizontalScroller>
  );
};

export default HeaderBottomBlock;
