/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useMemo, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from '@mui/material';

import useNewTracking from 'components/Globals/Analytics';
import { GOOGLE_OLD_TRACKING_SERVICES, SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import Drawer from 'components/uiLibrary/Drawer';
import Loader from 'components/uiLibrary/Loader';

import { allLanguageCodes } from 'constants/languageCodes';

import { useTranslation } from 'src/i18n';
import { TP } from 'constants/index';

import usePageContext from 'utils/hooks/usePageContext';
import useAppContext from 'utils/hooks/useAppContext';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { TRACK_EVENTS, PROFILE_DROPDOWN_EVENT_IDS } from 'utils/tracking';
import useLanguageDisplayLabel from 'utils/hooks/useLanguageDisplayLabel';

import classes from './LanguageSelector.module.scss';

const LANGUAGE_SELECTOR_TYPES = {
  HEADER: 'header',
  FOOTER: 'footer',
  MENU: 'menu',
};

const LanguageOption = ({ getUpdatedPath, language, isSelected, onClick, type }) => {
  const url = getUpdatedPath(language?.iso);
  const getLanguageLabel = useLanguageDisplayLabel();
  const isMenuType = type === LANGUAGE_SELECTOR_TYPES.MENU;
  const labelColor = useMemo(() => {
    if (isSelected) return 'link';
    if (isMenuType) return 'secondary';
    return 'primary';
  }, [isSelected, isMenuType]);

  return (
    <a
      className={classnames(classes.languageOption, { [classes.languageOption_disabled]: isSelected })}
      href={url}
      onClick={() => onClick(language?.iso)}
    >
      <span
        className={classnames(classes.languageOption__radioWrapper, {
          [classes.languageOption__menuRadioWrapper]: isMenuType,
        })}
      >
        <input
          className={classnames(classes.languageOption__radioInput, {
            [classes.languageOption__menuRadioInput]: isMenuType,
          })}
          type="radio"
          checked={isSelected}
        />
      </span>
      <Typography
        className={classes.languageOption__label}
        weight={isSelected ? 'bold' : 'regular'}
        color={labelColor}
        size={isMenuType ? 13 : 14}
      >
        {getLanguageLabel(language)}
      </Typography>
    </a>
  );
};

const GroupedLanguages = ({ title, languages, currentLanguage, getUpdatedPath, onChange, type }) => {
  const isMenuType = type === LANGUAGE_SELECTOR_TYPES.MENU;
  return (
    <div>
      <div className={classnames(classes.title, { [classes.menuTitle]: isMenuType })}>
        <Typography size={isMenuType ? 13 : 14} weight={isMenuType ? 'medium' : 'bold'}>
          {title}
        </Typography>
      </div>
      <ul className={classnames(classes.options, { [classes.menuOptions]: isMenuType })}>
        {languages.map(language => (
          <LanguageOption
            key={language.iso}
            language={language}
            getUpdatedPath={getUpdatedPath}
            isSelected={language?.iso === currentLanguage?.iso}
            onClick={onChange}
            type={type}
          />
        ))}
      </ul>
    </div>
  );
};

const LanguageList = ({ currentLanguage, onChange, type }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { pathname, navigate } = usePageContext();
  const { getSuggestedLanguages } = useAppContext();
  const isMenuType = type === LANGUAGE_SELECTOR_TYPES.MENU;
  const [path, query] = useMemo(
    () =>
      navigate
        .removeLocaleFromPath(pathname)
        ?.replace(/\/$/, '')
        ?.split('?'),
    [navigate, pathname],
  );

  const getUpdatedPath = useCallback(language => `${path}/${language}${query ? `?${query}` : ''}`, [path, query]);

  const suggestedLanguageOptions = getSuggestedLanguages();

  return (
    <>
      <GroupedLanguages
        title={t(`${TP}.FN_SUGGESTED_LANGUAGE`)}
        languages={suggestedLanguageOptions}
        currentLanguage={currentLanguage}
        getUpdatedPath={getUpdatedPath}
        onChange={onChange}
        type={type}
      />
      <div className={classnames(classes.separator, { [classes.menuSeparator]: isMenuType })}></div>
      <GroupedLanguages
        title={t(`${TP}.FN_FILTER_ALL`)}
        languages={allLanguageCodes}
        currentLanguage={currentLanguage}
        getUpdatedPath={getUpdatedPath}
        onChange={onChange}
        type={type}
      />
    </>
  );
};

const LoaderWrapper = ({ isLoading }) => (
  <div className={classes.globalLoader}>
    <Loader isLoading={isLoading} />
  </div>
);

const LanguageSelector = ({ type, renderAs = 'popUp', isProfileDropdown }) => {
  const ref = useRef();
  const track = useNewTracking();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:960px)');
  const { language, languageSwitcher } = useAppContext();

  const { t } = useTranslation('NS_APP_GLOBALS');
  const getLanguageLabel = useLanguageDisplayLabel();

  const currentLanguage = useMemo(() => allLanguageCodes.find(lang => lang.iso === language), [language]);

  const handleLanguageSelect = langIso => {
    languageSwitcher.onSetPreferredLanguage(langIso);
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        data: {
          id: isProfileDropdown
            ? PROFILE_DROPDOWN_EVENT_IDS.PROFILE_DROPDOWN_SELECT_LANGUAGE
            : TRACK_EVENTS.SELECT_LANGUAGE,
        },
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
    track.click({
      section: SECTIONS.LANGUAGE_SELECTOR,
      component: COMPONENTS.LANGUAGE,
      meta: { langIso },
    });

    setLoading(true);
  };

  useOnClickOutside(ref, () => {
    if (!isMobile) {
      setOpen(false);
    }
  });

  if (renderAs !== 'popUp') {
    return (
      <>
        {type === LANGUAGE_SELECTOR_TYPES.FOOTER && (
          <div className={classes.languageList__header}>
            <Typography size={16} weight="bold">
              {t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)}
            </Typography>
          </div>
        )}
        <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} type={type} />
        {isLoading && <LoaderWrapper isLoading={isLoading} />}
      </>
    );
  }

  return (
    <div className={classes.root} ref={ref} aria-label="Languages list">
      <div
        className={classnames(classes.selector, {
          [classes.selector_forFooter]: type === LANGUAGE_SELECTOR_TYPES.FOOTER,
          [classes.selector_forHeader]: type === LANGUAGE_SELECTOR_TYPES.HEADER,
          [classes.isSelected]: isOpen && type !== LANGUAGE_SELECTOR_TYPES.HEADER,
          [classes.isSelectedForHeader]: isOpen && type === LANGUAGE_SELECTOR_TYPES.HEADER,
        })}
        onClick={() => setOpen(!isOpen)}
      >
        <SpriteIcon icon="world" {...(type === LANGUAGE_SELECTOR_TYPES.HEADER && { className: classes.worldIcon })} />
        <span className={classes.current}>
          {[LANGUAGE_SELECTOR_TYPES.FOOTER, LANGUAGE_SELECTOR_TYPES.HEADER].includes(type)
            ? getLanguageLabel(currentLanguage)
            : currentLanguage?.iso}
        </span>
        {type === LANGUAGE_SELECTOR_TYPES.HEADER ? (
          <SpriteIcon icon={isOpen ? 'expand_less' : 'keyboard_arrow_down'} size="13" className={classes.arrowIcon} />
        ) : (
          <SpriteIcon icon="arrow_drop_down" />
        )}
      </div>

      {!isMobile && isOpen && (
        <div
          className={classnames(classes.languageList, {
            [classes.languageList_forFooter]: type === LANGUAGE_SELECTOR_TYPES.FOOTER,
          })}
        >
          <div className={classes.languageList__header}>
            <Typography size={16} weight="bold">
              {t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)}
            </Typography>
          </div>
          <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} />
          {isLoading && <LoaderWrapper isLoading={isLoading} />}
        </div>
      )}
      {isMobile && isOpen && (
        <Drawer isOpen={isOpen} title={t(`${TP}.FN_LANGUAGE_SELECTOR_TITLE`)} onClose={() => setOpen(false)}>
          <LanguageList currentLanguage={currentLanguage} onChange={handleLanguageSelect} />
          {isLoading && <LoaderWrapper isLoading={isLoading} />}
        </Drawer>
      )}
    </div>
  );
};

export default LanguageSelector;
