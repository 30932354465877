import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';

import { useVisitsHistory } from 'utils/search/visitsHistory';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';

import { TP, SEARCH_ENTITY_TYPE_MAP } from 'constants/index';

import SearchResultCard from '../SearchResultCard';

import classes from './RecentSearches.module.scss';

const RecentSearches = ({ onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const visitHistory = useVisitsHistory();

  if (!visitHistory.length) {
    return null;
  }

  return (
    <div className={classnames(classes.recentSearches, { [classes.recentSearches__mobile]: !isDesktop })}>
      <Typography size="13" weight="medium" className={classes.recentSearches__title}>
        {t(`${TP}.RECENT_PAGES`)}
      </Typography>
      <div className={classes.recentSearches__items}>
        {visitHistory.map(visit => (
          <SearchResultCard
            key={visit?.entity?.id}
            entity={visit?.entity}
            entityType={SEARCH_ENTITY_TYPE_MAP[visit?.type]}
            isLink
            onClose={onClose}
          />
        ))}
      </div>
    </div>
  );
};

export default RecentSearches;
