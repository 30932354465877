import React, { useMemo } from 'react';
import classnames from 'classnames';

import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import { TextButton } from 'components/uiLibrary/LinkButton';
import NotificationBanner from 'components/NotificationBanner';
import AdvancedSearch from 'components/Search/AdvancedSearch';

import { COMPONENTS, SECTIONS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import useAppContext from 'utils/hooks/useAppContext';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { NAV_LINKS } from 'constants/header';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import LogoImage from 'public/images/logo.png';

import HeaderLeftBlock from './HeaderLeftBlock';
import HeaderRightBlock from './HeaderRightBlock';
import HeaderBottomBlock from './HeaderBottomBlock';
import GlobalSearch from './GlobalSearch';
import HeaderSearch from './HeaderSearch';

import classes from './Header.module.scss';

const searchTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.GLOBAL_SEARCH,
};

const headerTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.NAVIGATION,
};

const obLogoTrackingData = {
  section: SECTIONS.GLOBAL_HEADER,
  component: COMPONENTS.OPERABASE_BUTTON,
};

const HeaderMobile = ({ links = [], trackingData, isCastingView, isHomePage }) => (
  <>
    <div className={classes.mobileHeader__sectionTop}>
      <HeaderLeftBlock trackingData={trackingData} isCastingView={isCastingView} />
      <HeaderRightBlock trackingData={trackingData} />
    </div>
    <div className={classes.mobileHeader__sectionMiddle}>
      <HeaderLeftBlock showProTools trackingData={trackingData} isCastingView={isCastingView} />
    </div>
    <div
      className={classnames(classes.mobileHeader__sectionBottom, {
        [classes.mobileHeader__sectionBottom_casting]: isCastingView,
      })}
    >
      {!isCastingView && (
        <div className={classnames({ [classes.mobileHeader__sectionBottom_withSearch]: !isHomePage })}>
          <HeaderBottomBlock links={links} trackingData={trackingData} />
        </div>
      )}
      {(isCastingView || !isHomePage) && (
        <div className={classes.mobileHeader__searchSection}>
          {isCastingView && <AdvancedSearch trackingData={searchTrackingData} />}
          {!isCastingView && !isHomePage && <GlobalSearch />}
        </div>
      )}
    </div>
  </>
);

const HeaderDesktop = ({ links = [], homePageLinkProps, isCastingView, trackingData, isHomePage }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.header__content}>
      <div className={classes.header__sectionTopContainer}>
        <div className={classes.header__sectionTop}>
          <HeaderLeftBlock
            trackingData={trackingData}
            homePageLinkProps={homePageLinkProps}
            isCastingView={isCastingView}
          />
          <HeaderRightBlock trackingData={trackingData} />
        </div>
      </div>
      <div className={classes.header__sectionBottomContainer}>
        {!isCastingView && (
          <div
            className={classnames(classes.header__sectionMiddle, { [classes.header__sectionMiddle_home]: isHomePage })}
          >
            <TextButton
              {...homePageLinkProps}
              isLink
              styles={{ root: classes.header__logoSection }}
              trackingData={obLogoTrackingData}
            >
              <Image
                src={LogoImage}
                alt="Operabase Home"
                lazy={false}
                className={classes.header__logoSection_img}
                disableCloudinary
                disableNextImage
              />
              <Typography size="10" className={classes.header__logoSection_since}>
                {t(`${TP}.LP_SINCE`)}
              </Typography>
            </TextButton>
            <HeaderBottomBlock links={links} trackingData={trackingData} />
          </div>
        )}
        {!isHomePage && (
          <div
            className={classnames(classes.header__sectionBottom, {
              [classes.header__sectionBottom_casting]: isCastingView,
            })}
          >
            <div
              className={classnames(classes.header__oldGlobalSearch, {
                [classes.header__oldGlobalSearch_casting]: isCastingView,
              })}
            >
              <HeaderSearch />
            </div>
            {!isCastingView && (
              <>
                <GlobalSearch />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Header = ({ isStatic }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate, isHeaderVisible, baseRoute, permissions } = usePageContext();
  const { obRouteContext } = useAppContext();
  const { url } = obRouteContext;
  const isHomePage = url === '/';
  const { profileData } = useGetTargetProfile();
  const homePageLinkProps = navigate.getLinkProps({ onlyLinkProps: true });
  const { isAdmin, castingToolPermissions } = permissions;
  const isCastingPage = baseRoute === ROUTE_RESERVED_KEYWORDS.casting;
  const hasCastingToolAccess = isAdmin || castingToolPermissions?.hasAccess;
  const isCastingView = isCastingPage && hasCastingToolAccess;

  const { data: statsNumbers } = useQuery(queries.getHomeNumbers());

  const navigationLinks = useMemo(
    () =>
      Object.entries(NAV_LINKS).map(([key, link]) => ({
        ...link,
        title: t(link.title),
        total: statsNumbers?.data?.[key]?.total || 0,
        type: key,
      })),
    [statsNumbers, t],
  );

  return (
    <>
      <header
        className={classnames({
          [classes.header]: isDesktop,
          [classes.mobileHeader]: !isDesktop,
          [classes.static]: isStatic,
          [classes.hideHeader]: !isHeaderVisible,
        })}
      >
        {!isDesktop ? (
          <HeaderMobile
            isHomePage={isHomePage}
            links={navigationLinks}
            trackingData={headerTrackingData}
            isCastingView={isCastingView}
          />
        ) : (
          <HeaderDesktop
            homePageLinkProps={homePageLinkProps}
            links={navigationLinks}
            trackingData={headerTrackingData}
            isCastingView={isCastingView}
            isHomePage={isHomePage}
          />
        )}
      </header>
      <NotificationBanner profileId={profileData?.id} />
    </>
  );
};

export default Header;
