import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TextButton } from 'components/uiLibrary/LinkButton';
import Image from 'components/uiLibrary/Image';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';

import LogoImage from 'public/images/logo.png';

import NavLink from '../NavLink';

import classes from './HeaderLeftBlock.module.scss';

const LINKS = [
  {
    baseRoute: ROUTE_RESERVED_KEYWORDS.casting,
    title: `${TP}.FN_CASTINGTOOL`,
  },
  {
    baseRoute: ROUTE_RESERVED_KEYWORDS.artists,
    title: `${TP}.TALENT_SEARCH`,
  },
  {
    baseRoute: ROUTE_RESERVED_KEYWORDS.rentals,
    title: `${TP}.FN_SOL_RENTAL_PRODUCTIONS`,
  },
  {
    baseRoute: ROUTE_RESERVED_KEYWORDS.managers,
    title: `${TP}.LP_AGENCY_ROSTER`,
  },
  {
    title: `${TP}.TICKETS_AUDIENCE_REACH`,
  },
  {
    title: `${TP}.LP_ARCHIVES`,
  },
  {
    title: `${TP}.ARTIST_PROFILE`,
  },
];

const ProTools = ({ homePageLinkProps, isCastingView, trackingData, onOpenProTools, showProTools = false }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, baseRoute } = usePageContext();

  return (
    <div className={classes.proToolsScroller}>
      {isCastingView ? (
        <div className={classes.publicSiteLink}>
          <NavLink
            typographyProps={{ title: { weight: 'medium', textTransform: 'uppercase' } }}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.PRO_PUBLIC_SEARCH_CTA }}
            {...homePageLinkProps}
            title={t(`${TP}.PUBLIC_SITE`)}
          />
          <SpriteIcon icon="turn_left" size="14" className={classes.turnLeftIcon} />
          {!showProTools && <div className={classes.divider} />}
        </div>
      ) : (
        <>
          <NavLink
            title={t(`${TP}.PRO_TOOLS_FOR`)}
            dropdown
            typographyProps={{ title: { size: 12, weight: 'medium', color: 'primary', textTransform: 'uppercase' } }}
            trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.PRO_TOOLS_CTA }}
            onClick={onOpenProTools}
            styles={{ root: classes.proToolsDropdown, icon: classes.arrowDownIcon }}
          />
          {!showProTools && <div className={classes.divider} />}
        </>
      )}
      <nav>
        <ul className={classes.proTools}>
          {LINKS.map(link => (
            <li
              key={link.title}
              className={classnames(classes.navItem, {
                [classes.navItem__selected]: baseRoute === link?.baseRoute,
              })}
            >
              <NavLink
                title={t(link.title)}
                typographyProps={{
                  title: {
                    size: 12,
                    textTransform: 'uppercase',
                    color: 'primary',
                    ...(baseRoute === link?.baseRoute && { weight: 'bold' }),
                  },
                }}
                trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.PRO_TOOLS_LINKS }}
                {...(link?.baseRoute && { ...navigate.getLinkProps({ baseRoute: link.baseRoute }) })}
              />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

const HeaderLeftBlock = ({ trackingData, isCastingView, showProTools }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();
  const { navigate } = usePageContext();
  const { setIsMenuOpen, setIsProToolsOpen } = useDialogs();
  const homePageLinkProps = navigate.getLinkProps({ onlyLinkProps: true });

  if (!isDesktop && !showProTools) {
    return (
      <ul className={classes.leftBlock}>
        <li className={classes.navItem}>
          <TextButton
            onClick={() => setIsMenuOpen(true)}
            trackingData={{
              ...trackingData,
              subComponent: SUB_COMPONENTS.MENU,
            }}
            disableUnderline
            styles={{ root: classes.menu }}
            leftIcon={<SpriteIcon icon="menu" size="16" className={classes.menuIcon} />}
          ></TextButton>
        </li>
        <li className={classes.navItem}>
          <TextButton {...homePageLinkProps} isLink disableUnderline>
            <Image
              src={LogoImage}
              alt="Operabase Home"
              lazy={false}
              className={classes.leftBlock__logoImage}
              disableCloudinary
              disableNextImage
            />
            <Typography size="10">{t(`${TP}.LP_SINCE`)}</Typography>
          </TextButton>
        </li>
      </ul>
    );
  }

  return (
    <div className={classes.leftBlock}>
      {showProTools ? (
        <HorizontalScroller hideArrow>
          <ProTools
            homePageLinkProps={homePageLinkProps}
            isCastingView={isCastingView}
            trackingData={trackingData}
            showProTools={showProTools}
            onOpenProTools={() => setIsProToolsOpen(true)}
          />
        </HorizontalScroller>
      ) : (
        <ProTools
          homePageLinkProps={homePageLinkProps}
          isCastingView={isCastingView}
          trackingData={trackingData}
          onOpenProTools={() => setIsProToolsOpen(true)}
        />
      )}
    </div>
  );
};

export default HeaderLeftBlock;
