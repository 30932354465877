/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popper from 'components/uiLibrary/Popper';
import LinkButton from 'components/uiLibrary/LinkButton';

import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { useVisitsHistory } from 'utils/search/visitsHistory';
import usePageContext from 'utils/hooks/usePageContext';

import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { route } from 'constants/routes';

import RecentSearches from '../GlobalSearch/RecentSearches';

import classes from './History.module.scss';

const History = ({ sticky = false, trackingData }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const visitHistory = useVisitsHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMouseEnter = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <LinkButton
        isLink
        variant="text"
        disableUnderline
        {...navigate.getLinkProps({ baseRoute: route.VISITS_HISTORY })}
        styles={{
          root: classnames(classes.history, {
            [classes.history__selected]: open,
            [classes.history__sticky]: sticky,
          }),
        }}
        trackingData={{
          ...trackingData,
          subComponent: SUB_COMPONENTS.HISTORY,
        }}
        {...(sticky && { leftIcon: <SpriteIcon icon="history" size="16" className={classes.historyIcon} /> })}
      >
        <Typography size="12" className={classes.title}>
          {t(`${TP}.YOUR_HISTORY`)}
        </Typography>
        {!sticky && (
          <SpriteIcon icon={open ? 'expand_less' : 'keyboard_arrow_down'} size="16" className={classes.icon} />
        )}
      </LinkButton>
      {open && (
        <Popper open anchorEl={anchorEl}>
          <div className={classes.popperContainer}>
            {visitHistory?.length > 0 ? (
              <RecentSearches onClose={handleMouseLeave} />
            ) : (
              <Typography className={classes.noResult}>{t(`${TP}.NO_HISTORY_MESSAGE`)}</Typography>
            )}
          </div>
        </Popper>
      )}
    </div>
  );
};

export default History;
